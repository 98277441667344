<template>
  <span >
    <vue-advanced-chat
        :current-user-id="patientId"
        :height="height"
        show-reaction-emojis="false"
        show-audio="false"
        show-emojis="false"
        accepted-files="image/png, image/jpeg, application/pdf"
        emojis-suggestion-enabled="false"
        show-add-room="false"
        scroll-distance="20"
        :rooms="JSON.stringify(filterdRooms)"
        :rooms-loaded="roomsLoaded"
        :loading-rooms="loadingRooms"
        :room-actions="JSON.stringify(roomActions)"
        :messages="JSON.stringify(messages)"
        :messages-loaded="messagesLoaded"
        :styles="JSON.stringify(styles)"
        @send-message="sendMessage"
        @fetch-messages="roomDetails"
    >
       <div slot="room-header-info">
         <v-row>
           <v-col>
         <span class="d-flex justify-center font-weight-bold color-black">
           {{ roomHeader }}
         </span>
             </v-col>
           </v-row>
       </div>
      <div v-for="room in filterdRooms" :key="room.roomId" :slot="'room-list-item_' + room.roomId">
          <span class="d-flex align-center justify-end color-primary" :style="room.status === 1 ? 'font-size: 12px' : 'color: black; font-size: 12px'">{{room.lastMessage.content}}</span>
            <v-row>
              <v-col>
                <v-avatar
                    :color="room.status === 1 ? 'primary' : 'secondary'"
                    :style="room.status === 1 ? 'color: white;' : 'color: black;'"
                    size="36"
                >{{room.roomId}}</v-avatar>
              </v-col>
               <span class="d-flex align-center color-primary justify-end" :style="room.status === 1 ? 'color: #6600FF; font-size: 20px' : 'color: black; font-size: 20px'">{{room.roomName}}</span>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
            </v-row>
        {{room.roomUsername}}
          <span class="d-flex align-end justify-end mb-2 elevation-0">
                <v-btn v-if="room.status === 1" @click="changeStatus(room)" elevation="2" color="primary" height="20">{{$t('finish')}}</v-btn>
                <v-btn v-else elevation="2" @click="changeStatus(room)" :color="room.status === 1 ? 'primary' : 'secondary'" :style="room.status === 1 ? 'color: white; height: 20px' : 'color: black; height: 20px'">{{$t('re-open')}}</v-btn>
            </span>
      </div>
      <div slot="rooms-header">
        <v-btn-toggle
            class="my-2"
            v-model="status"
            row
            mandatory
            active-class="chips white--text"
            rounded
        >
              <v-btn
                  value='All'
                  width="60"
                  height="20"
                  class="font-size11"
              >{{$t('all')}}</v-btn>
              <v-btn
                  value='Opened'
                  width="60"
                  height="20"
                  class="font-size11"
              >{{$t('on-going')}}</v-btn>
          <v-btn
              value='Closed'
              width="60"
              height="20"
              class="font-size11"
          >{{$t('finished')}}</v-btn>
            </v-btn-toggle>
      </div>

    <div
        v-for="room in rooms"
        :slot="'room-list-avatar_' + room.roomId"
        :key="room._id"
    >
      <v-avatar
          style="color: white"
          color="primary"
          size="35"
      >{{ room.roomId }}</v-avatar>
    </div>
  </vue-advanced-chat>
  </span>
</template>
<script>
import { mapState } from 'vuex';
import { register } from 'vue-advanced-chat';
import { formatterFilter } from '@/utils/luxon-formater';
register();
export default {
  data () {
    return {
      BASE64: '',
      currentUserId: '',
      rooms: [],
      patientId: '',
      filterdRooms: [],
      users: [],
      roomHeader: '',
      roomsLoaded: true,
      status: 'All',
      messagesLoaded: false,
      loadingRooms: false,
      roomEx: [],
      files: '',
      messages: [],
      styles: {
        general: {
          color: '#6600FF',
          colorSpinner: '#6600FF',
          colorButton: '#6600FF',
          colorButtonClear: '#6600FF',
          backgroundColorButton: '#E8E8E8',
          backgroundInput: 'white',
          colorPlaceholder: '#E8E8E8',
          colorCaret: '#6600FF',
          backgroundScrollIcon: '#E8E8E8',
          borderRadius: '12px',
        },

        container: {
          border: '0px solid #3A3A3A',
          borderRadius: '12px',
          boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
        },

        message: {
          background: '#b5b5b5',
          backgroundMe: '#6600FF',
          color: '#E8E8E8',
          colorUsername: '#ffffff',
          colorDate: '#6e6e6e',
          backgroundDate: 'white',
        },

        icons: {
          search: '#9ca6af',
          add: '#1976d2',
          toggle: 'black',
          menu: '#6600FF',
          close: '#6600FF',
          closeImage: '#6600FF',
          file: '#6600FF',
          paperclip: '#6600FF',
          closeOutline: '#6600FF',
          closePreview: '#6600FF',
          send: '#6600FF',
          sendDisabled: '#9ca6af',
          emoji: '#6600FF',
          emojiReaction: 'rgba(0, 0, 0, 0.3)',
          document: '#6600FF',
          pencil: '#6600FF',
          checkmark: '#E8E8E8',
          checkmarkSeen: '#E8E8E8',
          eye: '#fff',
          dropdownMessage: '#fff',
          dropdownRoom: '#6600FF',
          dropdownScroll: '#6600FF',
        },

        footer: {
          background: '#E8E8E8',
          backgroundReply: 'rgba(0, 0, 0, 0.08)',
          color: '#6600FF',
        },
      },
      messIndex: 0,
      roomActions: [
        { name: 'deleteRoom', title: 'Close Issue' },
      ],
      timeLocalization: this.$i18n.locale,
    };
  },
  computed: {
    ...mapState({
      supportMessagesFromRepo: (state) => state.hospitals.supportMessage,
      getChatDetails: (state) => state.hospitals.getChatDetails,
      userData: (state) => state.authentication.userData,
    }),
    height () {
      return `${window.innerHeight - 130}px`;
    },
  },
  async created () {
    this.currentUserId = this.userData.id;
    await this.getSupportMessages();
  },
  watch: {
    status (val) {
      if (val === 'Closed') {
        this.filterdRooms = this.rooms.filter(item => item.status === 2);
      } else if (val === 'Opened') {
        this.filterdRooms = this.rooms.filter(item => item.status === 1);
      } else {
        this.filterdRooms = this.rooms;
      }
    },
  },
  methods: {
    changeStatus (status) {
      var statusChage = null;
      if (status.status === 1) {
        statusChage = 2;
      } else {
        statusChage = 1;
      }
      var body = {
        RelatedToSupportMessageId: status.roomId,
        StatusIdVAlue: statusChage,
      };
      this.$store.dispatch('hospitals/changeSupportMessageStatus', body).then(() => {
        this.rooms = [];
        this.messages = [];
        this.getSupportMessages();
      });
    },

    sendMessage (message) {
      this.messIndex = 0;
      if (message.detail[0].content !== ' ') {
        var mess = {
          _id: `${this.messIndex - 1}`,
          indexId: `${this.userData.id}`,
          content: message.detail[0].content,
          senderId: `${this.userData.id}`,
          username: this.userData.firstName + '' + this.userData.lastName,
          date: formatterFilter(new Date().toISOString(), 'datetime_short_with_seconds_timezone', this.timeLocalization, this.userData.userTimezone),
          timestamp: formatterFilter(new Date().toISOString(), 'time', this.timeLocalization, this.userData.userTimezone),
        };
        var body = {
          SupportCategoryId: this.getChatDetails[0].supportCategoryId,
          MessageForSupport: message.detail[0].content,
          RelatedToSupportMessageId: this.getChatDetails[0].relatedToSupportMessageId,
        };
        this.$store.dispatch('hospitals/sendSupportMessage', body);
      }
      if (message.detail[0].files !== null) {
        const blob = new Blob([message.detail[0].files[0].blob], { type: message.detail[0].files[0].type });
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        setTimeout(() => {
          const formData = new FormData();
          formData.append('formFile', message.detail[0].files[0].blob, message.detail[0].files[0].type);
          formData.append('fileName', 'Issue Image');
          var bodySend = {
            RelatedToSupportMessageId: this.getChatDetails[0].relatedToSupportMessageId,
            FormDataSend: formData,
          };
          var files = [];
          this.$store.dispatch('hospitals/sendFileSupportMessage', bodySend).then(res => {
            var file = {
              name: 'Issue',
              size: 67351,
              type: 'jpeg',
              url: 'data:image/jpeg;base64,' + res.data.attachmentsList[0].attachedFile,
            };
            files.push(file);
            mess.files = files;
            this.messages.push(mess);
          });
        }, 1000);
      } else {
        this.messages.push(mess);
      }
    },
    async getSupportMessages () {
      this.loading = true;
      await this.$store
          .dispatch('hospitals/supportmessage', this.hospitalId)
          .then(() => {
            this.loading = false;
            this.supportMessagesFromRepo.forEach(item => {
              var user = {};
              var users = [];
              var room = {
                status: item.status,
                roomId: `${item.supportMessageId}`,
                patientId: `${item.patientId}`,
                roomHeader: item.patientFullname,
                roomName: 'Issue #' + item.supportMessageId,
                lastMessage: {
                  content: `${formatterFilter(new Date(item.lastMessageTimestamp).toISOString(), 'datetime_short_with_seconds_timezone', this.timeLocalization, this.userData.userTimezone)}`,
                },
              };
              item.usersRelated.forEach(element => {
                user = {
                  _id: `${element.userId}`,
                  username: element.userFullname,
                };
                users.push(user);
              });
              room.users = users;
              this.rooms.push(room);
            });
            this.filterdRooms = this.rooms;
          });
    },
    async roomDetails (room) {
      var roomId = room.detail[0]?.room.roomId;
      this.patientId = room.detail[0]?.room.patientId;
      this.roomHeader = room.detail[0]?.room.roomHeader;
      this.messages = [];
      await this.$store.dispatch('hospitals/roomDetails', roomId).then(() => {
        this.getChatDetails.forEach(item => {
          var mess = {
            _id: `${item.id}`,
            indexId: `${item.userId}`,
            content: item.messageForSupport,
            senderId: `${item.userId}`,
            username: item.userFullname,
            date: formatterFilter(new Date(item.createdTimestamp).toISOString(), 'datetime_short_with_seconds_timezone', this.timeLocalization, this.userData.userTimezone),
            timestamp: formatterFilter(new Date(item.createdTimestamp).toISOString(), 'time', this.timeLocalization, this.userData.userTimezone),
          };
          if (item.containsImages) {
            var files = [];
            item.attachmentsList.forEach(element => {
              var file = {
                name: 'Issue',
                size: 67351,
                type: 'jpeg',
                url: 'data:image/jpeg;base64,' + element.attachedFile,
              };
              files.push(file);
            });
            mess.files = files;
          }
          this.messages.push(mess);
        });
        this.messagesLoaded = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
